import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import clsx from "clsx";
import LanguageCurrencyChanger from "./sub-components/LanguageCurrencyChanger";
import { useTranslation } from "react-i18next";

const HeaderTop = ({ borderStyle }) => {
  const { t, i18n } = useTranslation();
  const currency = useSelector((state) => state.currency);

  // Determine if the current language is Turkish
  const isTurkish = i18n.language === "fn"; // Assuming 'tr' is the language code for Turkish
  const isArabic = i18n.language === "de"; // Assuming 'ar' is the language code for Arabic

  const amount = (
    <span style={{ color: "red" }}>
      { (1000 * currency.currencyRate).toFixed(2)+currency.currencySymbol }
    </span>
  );

  return (
    <div
      className={clsx(
        "header-top-wap",
        borderStyle === "fluid-border" && "border-bottom"
      )}
    >
      <LanguageCurrencyChanger currency={currency} />
      <div className="header-offer">
        <p>
          {isTurkish
            ? // Turkish: amount at the beginning
              <>
                {amount} {t("Free delivery on order over")}
              </>
            : // Arabic and English: amount at the end
              <>
                {t("Free delivery on order over")} {amount}
              </>}
        </p>
      </div>
    </div>
  );
};

HeaderTop.propTypes = {
  borderStyle: PropTypes.string,
};

export default HeaderTop;
